import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import _ from 'lodash'

import {
  ActivityDTO,
  attemptDeleteActivity,
  attemptPublishActivity,
} from './activitySlice'
import { attemptDeletePost, attemptPublishPost, PostDTO } from './postsSlice'
import { attemptDeleteService, attemptPublishService, ServiceDTO } from './serviceSlice'
import $api from '../utils/tools/api'
import { FEED_CHUNK_SIZE } from '../utils/constants'

export interface FeedEntry {
  activity?: ActivityDTO
  service?: ServiceDTO
  post?: PostDTO
}

export enum FEED_TYPE {
  activity = 'activity',
  service = 'service',
  post = 'post',
}

export interface CommentDTO {
  entity: string //id
  text: string
  repliedTo?: string //id
}
// export type FeedEntry =
//   | { activity: ActivityDTO; service?: undefined; post?: undefined }
//   | { service: ServiceDTO; activity?: undefined; post?: undefined }
//   | { post: PostDTO; activity?: undefined; service?: undefined }

interface FetchFeedDTO {
  olderThan?: string //dayjs.Dayjs.toISOString()
  limit: number
}

interface State {
  feed?: FeedEntry[]
  hasMore?: boolean
}

export const initialState: State = {
  feed: [],
}
//#region methods

export const attemptFetchFeed = createAsyncThunk<FeedEntry[], FetchFeedDTO>(
  'feed/fetch',
  async (payload, { getState, dispatch }) => {
    const response = await $api.post(`feed`, payload)
    return response.data
  },
)

export const attemptRefreshFeed = createAsyncThunk<
  FeedEntry[],
  Omit<FetchFeedDTO, 'olderThan'>
>('feed/refresh', async (payload, { getState, dispatch }) => {
  const response = await $api.post(`feed`, payload)
  // await useDev().delay(3000)
  return response.data
})

export const attemptHardRefreshFeed = createAsyncThunk<
  FeedEntry[],
  Omit<FetchFeedDTO, 'olderThan'>
>('feed/hard-refresh', async (payload, { getState, dispatch }) => {
  const response = await $api.post(`feed`, payload)
  // await useDev().delay(3000)
  return response.data
})

// export const getIdentity = createAsyncThunk<UserIdentityInterface>(
//   'profile/get-information',
//   async (payload) => {
//     const response = await $api.get('/profile')
//     return response.data
//   },
// )

//#endregion
//#region slice
const filterDeletedFeedItem = (feed: FeedEntry[], id: string) => {
  return feed?.filter(
    (item) => id !== (item.activity?._id || item.post?._id || item.service?._id),
  )
}
export const feedSlice = createSlice({
  name: 'feedSlice',
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(attemptFetchFeed.pending, (state, { payload }) => {
      state.hasMore = undefined
    })
    builder.addCase(attemptFetchFeed.fulfilled, (state, { payload }) => {
      if (payload.length < FEED_CHUNK_SIZE) {
        state.hasMore = false
      } else {
        state.hasMore = true
      }
      const merged = _.merge(_.keyBy(state.feed, '_id'), _.keyBy(payload, '_id'))
      const uniqueFeed = _.values(merged)
      // uniqueFeed.shift() //to test attemptRefreshFeed
      state.feed = uniqueFeed
    })
    builder.addCase(attemptRefreshFeed.fulfilled, (state, { payload }) => {
      const merged = _.merge(_.keyBy(payload, '_id'), _.keyBy(state.feed, '_id'))
      const uniqueFeed = _.values(merged)
      state.feed = uniqueFeed
    })
    builder.addCase(attemptHardRefreshFeed.fulfilled, (state, { payload }) => {
      state.feed = payload
    })
    //#region external slice listeners
    builder.addCase(attemptPublishActivity.fulfilled, (state, { payload }) => {
      console.log('Start implementation of updating newsfeed on activity published')
      console.log('payload:', payload)
    })
    builder.addCase(attemptPublishPost.fulfilled, (state, { payload }) => {
      console.log('Start implementation of updating newsfeed on post published')
      console.log('payload:', payload)
    })
    builder.addCase(attemptPublishService.fulfilled, (state, { payload }) => {
      console.log('Start implementation of updating newsfeed on service published')
      console.log('payload:', payload)
    })

    builder.addCase(attemptDeletePost.fulfilled, (state, { payload: id }) => {
      state.feed = filterDeletedFeedItem(state.feed || [], id)
      // state.feed?.filter(
      //   (item) => id === (item.activity?._id || item.post?._id || item.service?._id),
      // )
    })
    builder.addCase(attemptDeleteActivity.fulfilled, (state, { payload: id }) => {
      state.feed = filterDeletedFeedItem(state.feed || [], id)
    })

    builder.addCase(attemptDeleteService.fulfilled, (state, { payload: id }) => {
      state.feed = filterDeletedFeedItem(state.feed || [], id)
    })
    //#end region
  },
})

export const {} = feedSlice.actions
export default feedSlice.reducer
