import 'dayjs/locale/en'
import 'dayjs/locale/fr'
import 'dayjs/locale/de'
import 'dayjs/locale/it'
import 'dayjs/locale/uk'
import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import authEN from './locales/auth.en.json'
import authFR from './locales/auth.fr.json'
import authUK from './locales/auth.uk.json'
import profileEN from './locales/profile.en.json'
import profileFR from './locales/profile.fr.json'
import profileUK from './locales/profile.uk.json'
import messengerEN from './locales/messenger.en.json'
import messengerFR from './locales/messenger.fr.json'
import messengerUK from './locales/messenger.uk.json'
import privacyEN from './locales/privacy.en.json'
import privacyFR from './locales/privacy.fr.json'
import privacyUK from './locales/privacy.uk.json'
import newsFeedEN from './locales/news-feeds.en.json'
import newsFeedFR from './locales/news-feeds.fr.json'
import newsFeedUK from './locales/news-feeds.uk.json'
import professionListEN from './locales/datasets/professions.en.json' //https://github.com/BogdanGorelkin/professions
import professionListFR from './locales/datasets/professions.fr.json'
import professionListUK from './locales/datasets/professions.uk.json'
import skillListEN from './locales/datasets/skills.en.json'
import skillListFR from './locales/datasets/skills.fr.json'
import skillListUK from './locales/datasets/skills.uk.json'
import interestsListEN from './locales/datasets/interests.en.json'
import interestsListFR from './locales/datasets/interests.fr.json'
import interestsListUK from './locales/datasets/interests.uk.json'
import frFR from 'antd/locale/fr_FR'
import enGB from 'antd/locale/en_GB'
import ukUA from 'antd/locale/uk_UA'
import { Locale } from 'antd/lib/locale'
import nationalitiesEN from 'i18n-nationality/langs/en.json'
import nationalitiesFR from 'i18n-nationality/langs/fr.json'
import nationalitiesUK from './locales/datasets/nationality.uk.json'
import languages from './locales/datasets/languages.json'
import commonEN from './locales/common.en.json'
import commonFR from './locales/common.fr.json'
import commonUK from './locales/common.uk.json'
import nationalities from 'i18n-nationality'

const nationalitiesResources: {
  [key: string]: { locale: string; nationalities: { [key: string]: string } }
} = {
  en: nationalitiesEN,
  fr: nationalitiesFR,
  uk: nationalitiesUK,
}
export type Professions = keyof typeof professionListEN
export type Skills = keyof typeof skillListEN
export type Interests = keyof typeof interestsListEN
export type LocaleType = 'fr' | 'de' | 'it' | 'uk' | 'en'

/**
 * Get the locale ant object from the locale string
 * @param locale i18n locale string
 * @returns Locale ant object
 */
export const antLocale: { [key: string]: Locale } = {
  fr: frFR,
  uk: ukUA,
  en: enGB,
}

export const resources = {
  en: {
    auth: authEN,
    profile: profileEN,
    professionsList: professionListEN,
    skillsList: skillListEN,
    interestsList: interestsListEN,
    publications: privacyEN,
    messenger: messengerEN,
    news_feed: newsFeedEN,
    languages: languages,
    common: commonEN,
  },
  fr: {
    auth: authFR,
    profile: profileFR,
    professionsList: professionListFR,
    skillsList: skillListFR,
    interestsList: interestsListFR,
    publications: privacyFR,
    messenger: messengerFR,
    news_feed: newsFeedFR,
    languages: languages,
    common: commonFR,
  },
  uk: {
    auth: authUK,
    profile: profileUK,
    professionsList: professionListUK,
    skillsList: skillListUK,
    interestsList: interestsListUK,
    publications: privacyUK,
    messenger: messengerUK,
    news_feed: newsFeedUK,
    languages: languages,
    common: commonUK,
  },
} // as const

i18n.use(initReactI18next).init(
  {
    lng: localStorage.getItem('settingsLng') || process.env.REACT_APP_DEFAULT_LOCALE,
    fallbackLng: 'en',
    resources,
    nsSeparator: false,
  },
  () => {
    // const nationalityResource = nationalitiesResources[i18n.language] || nationalitiesEN
    nationalities.registerLocale(nationalitiesResources['en'])
  },
)

i18n.on('languageChanged', (lng) => {
  localStorage.setItem('settingsLng', lng)
})

export function getNationalityOptions() {
  //TODO: add internationalization. getName will not work if
  // nationalities.registerLocale('f.e.: german resource')
  //but getName(code, 'en')

  const nationalityCodes = nationalities.getAlpha2Codes()
  const options = Object.keys(nationalityCodes).map((code) => ({
    label: nationalities.getName(nationalityCodes[code], 'en'),
    value: code,
  })) //TODO: get name accordingly to the current app language
  return options
}

export default i18n
