import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { AddressDTO } from '../utils/dto/address.dto'
import { Message } from '../features/messenger/useChat'
import { Neighbour } from './searchSlice'
import { UploadFile } from 'antd'

export enum CHAT_TYPE {
  P2P = 'P2P',
  OPEN = 'OPEN',
  CLOSED = 'CLOSED',
}

export interface OpenRoom {
  _id: string
  type: CHAT_TYPE.OPEN
  participants: Neighbour[]
  createdAt: string
  createdBy: Neighbour
  name: string
  address: AddressDTO
  image: string
}

export enum CandidateType {
  REQUEST = 'REQUEST',
  INVITE = 'INVITE',
}

export enum CandidateStatus {
  PENDING = 'PENDING',
  REJECTED = 'REJECTED',
}

export interface Candidate {
  user: Neighbour
  type: CandidateType
  status: CandidateStatus
  createdAt: string
}

export interface Room {
  _id: string
  type: CHAT_TYPE
  participants: Neighbour[]
  candidates?: Candidate[]
  lastMessage?: Message
  createdAt: string
  createdBy?: Neighbour
  name?: string
  address?: AddressDTO
  image?: string
  pendingRequests?: number
  myCandidate?: Candidate
  unreadMessagesCount?: number //TODO: implement
}

// export interface RoomClientSideInfo {
//   scrollPosition?: number
//   messageInputDraft?: MessageInputDraft
// }

export interface MessageInputDraft {
  messageToEdit?: Message | undefined
  messageToReply?: Message | undefined
  text?: string
  files?: UploadFile<any>[] | undefined
}

export type RoomId = string
interface State {
  allRooms: Record<RoomId, Room>
  messages: Record<RoomId, Message[]>
  candidateRooms: Record<RoomId, Room>
  reachableClosedRooms: Record<RoomId, Room>
  scrollPosition: Record<RoomId, number>
  messageInputDraft: Record<RoomId, MessageInputDraft>

  // messageInputDraft: {
  //   [chatId: string]: MessageInputDraft
  // }
  currentChat?: {
    id: string
    scrolledToBottom: boolean
    uploadFile: boolean
  }
}

export const initialState: State = {
  allRooms: {},
  messages: {},
  candidateRooms: {},
  reachableClosedRooms: {},
  scrollPosition: {},
  messageInputDraft: {},
}

function calcPendingRequests(r: Room, userId: string) {
  if (r.createdBy?._id === userId && r.candidates) {
    let count = 0
    for (const c of r.candidates) {
      if (c.type === CandidateType.REQUEST && c.status === CandidateStatus.PENDING) {
        count++
      }
    }

    return count
  }
}

function findMyCandidate(r: Room, userId: string) {
  return r.candidates?.find((c) => c.user._id === userId)
}

//#region slice
export const messengerReducer = createSlice({
  name: 'messenger',
  initialState,
  reducers: {
    setAllRooms: (
      state,
      { payload }: PayloadAction<{ userId: string; rooms: Room[] }>,
    ) => {
      state.allRooms = {}
      for (const r of payload.rooms) {
        state.allRooms[r._id] = {
          ...r,
          pendingRequests: calcPendingRequests(r, payload.userId),
        }
      }
    },

    setCandidateClosedRooms: (
      state,
      { payload }: PayloadAction<{ userId: string; rooms: Room[] }>,
    ) => {
      state.candidateRooms = {}
      for (const r of payload.rooms) {
        state.candidateRooms[r._id] = {
          ...r,
          myCandidate: findMyCandidate(r, payload.userId),
        }
      }
    },

    setReachableClosedRooms: (state, { payload: rooms }: PayloadAction<Room[]>) => {
      state.reachableClosedRooms = {}
      for (const r of rooms) {
        state.reachableClosedRooms[r._id] = r
      }
    },
    setRoom: (state, { payload }: PayloadAction<{ userId: string; room: Room }>) => {
      state.allRooms[payload.room._id] = {
        ...payload.room,
        pendingRequests: calcPendingRequests(payload.room, payload.userId),
      }
    },
    // setMessages: (state, { payload }: PayloadAction<{ roomId: RoomId, messages: Message[] }>) => {
    //   const { roomId, messages } = payload
    //   state.messages[roomId] = messages
    // },

    deleteParticipant: (
      state,
      { payload }: PayloadAction<{ roomId: string; userId: string }>,
    ) => {
      state.allRooms[payload.roomId].participants = state.allRooms[
        payload.roomId
      ].participants.filter((p) => p._id !== payload.userId)
    },

    setCandidateRoom: (
      state,
      { payload }: PayloadAction<{ userId: string; room: Room }>,
    ) => {
      state.candidateRooms[payload.room._id] = {
        ...payload.room,
        myCandidate: findMyCandidate(payload.room, payload.userId),
      }
    },

    kickedFromClosedRoom: (state, { payload: roomId }: PayloadAction<string>) => {
      const room = state.allRooms[roomId]
      if (room) {
        delete room.lastMessage
        delete room.candidates
        state.reachableClosedRooms[roomId] = room
        delete state.allRooms[roomId]
        delete state.messages[roomId]
      }
    },

    deleteCandidateRoom: (state, { payload: roomId }: PayloadAction<string>) => {
      const room = state.candidateRooms[roomId]
      if (room) {
        delete state.candidateRooms[roomId]
        state.reachableClosedRooms[roomId] = room
      }
    },

    requestedJoinClosedRoom: (
      state,
      { payload }: PayloadAction<{ room: Room; userId: string }>,
    ) => {
      state.candidateRooms[payload.room._id] = {
        ...payload.room,
        myCandidate: findMyCandidate(payload.room, payload.userId),
      }
      delete state.reachableClosedRooms[payload.room._id]
    },

    cancelRequestedJoinClosedRoom: (state, { payload: room }: PayloadAction<Room>) => {
      delete state.candidateRooms[room._id]
      state.reachableClosedRooms[room._id] = room
    },

    requestToJoinClosedRoomApproved: (state, { payload: room }: PayloadAction<Room>) => {
      state.allRooms[room._id] = room
      delete state.candidateRooms[room._id]
    },

    requestToJoinClosedRoomDeclined: (state, { payload: room }: PayloadAction<Room>) => {
      state.reachableClosedRooms[room._id] = room
      delete state.candidateRooms[room._id]
    },

    addMessage: (state, { payload: message }: PayloadAction<Message>) => {
      if (!state.messages[message.room]) {
        state.messages[message.room] = []
      }
      state.messages[message.room].unshift(message)
      state.allRooms[message.room].lastMessage = message
    },

    addMessages: (
      state,
      { payload }: PayloadAction<{ roomId: RoomId; messages: Message[] }>,
    ) => {
      const { roomId, messages } = payload
      if (!state.messages[roomId]) {
        state.messages[roomId] = []
      }
      const existingIds = new Set(state.messages[roomId].map((message) => message._id))
      const uniqueMessages = messages.filter((message) => !existingIds.has(message._id))
      state.messages[roomId] = state.messages[roomId].concat(uniqueMessages)
    },

    addCandidate: (
      state,
      { payload }: PayloadAction<{ roomId: string; candidate: Candidate }>,
    ) => {
      if (!state.allRooms[payload.roomId].candidates) {
        state.allRooms[payload.roomId].candidates = []
      }
      state.allRooms[payload.roomId].candidates?.push(payload.candidate)
    },

    deleteCandidate: (
      state,
      { payload }: PayloadAction<{ roomId: string; userId: string }>,
    ) => {
      state.allRooms[payload.roomId].candidates = state.allRooms[
        payload.roomId
      ].candidates?.filter((c) => c.user._id !== payload.userId)
    },

    resetMessageInputDraft(state, { payload }: PayloadAction<{ chatId: string }>) {
      const { chatId } = payload
      delete state.messageInputDraft[chatId]
    },

    markMessagesRead(state, { payload }: PayloadAction<string>) {
      console.log('TODO: implement markMessagesRead', payload)
      // if (state.allRooms[payload]) {
      //   state.allRooms[payload].unreadMessagesCount = 0
      // }
    },
    setMessageInputDraftEditMsg(
      state,
      {
        payload,
      }: PayloadAction<Pick<MessageInputDraft, 'messageToEdit'> & { chatId: string }>,
    ) {
      const { chatId, messageToEdit } = payload
      state.messageInputDraft[chatId] = {
        ...state.messageInputDraft[chatId],
        messageToReply: undefined,
        messageToEdit,
      }
    },
    setMessageInputDraftReplyMsg(
      state,
      {
        payload,
      }: PayloadAction<Pick<MessageInputDraft, 'messageToReply'> & { chatId: string }>,
    ) {
      const { chatId, messageToReply } = payload
      state.messageInputDraft[chatId] = {
        ...state.messageInputDraft[chatId],
        messageToEdit: undefined,
        messageToReply,
      }
    },
    setMessageInputDraftText(
      state,
      { payload }: PayloadAction<Pick<MessageInputDraft, 'text'> & { chatId: string }>,
    ) {
      //TODO: save draft on server side (allow user switch device)
      const { chatId, text } = payload
      state.messageInputDraft[chatId] = {
        ...state.messageInputDraft[chatId],
        text,
      }
    },
    saveChatScrollPosition(
      state,
      { payload }: PayloadAction<{ chatId: string; position: number }>,
    ) {
      state.scrollPosition[payload.chatId] = payload.position
    },
    // setScrolledToBottom(state, { payload }: PayloadAction<boolean>) {
    //   if (state.currentChat) {
    //     state.currentChat.scrolledToBottom = payload
    //   }
    // },
    setMessageInputDraftFiles(
      state,
      { payload }: PayloadAction<Pick<MessageInputDraft, 'files'> & { chatId: string }>,
    ) {
      const { chatId, files } = payload
      const _files = files && files.length > 0 ? files : undefined //if file has been added, and the removed files are equal to []
      state.messageInputDraft[chatId] = {
        ...state.messageInputDraft[chatId],
        files: _files,
      }
    },
  },
  extraReducers: (builder) => {},
})

export const {
  setAllRooms,
  setCandidateClosedRooms,
  setReachableClosedRooms,
  setRoom,
  deleteParticipant,
  setCandidateRoom,
  kickedFromClosedRoom,
  deleteCandidateRoom,
  requestedJoinClosedRoom,
  cancelRequestedJoinClosedRoom,
  requestToJoinClosedRoomApproved,
  requestToJoinClosedRoomDeclined,
  /* setMessages, */
  addMessage,
  addMessages,
  addCandidate,
  deleteCandidate,
  resetMessageInputDraft,
  markMessagesRead,
  setMessageInputDraftEditMsg,
  setMessageInputDraftReplyMsg,
  setMessageInputDraftText,
  setMessageInputDraftFiles,
  saveChatScrollPosition,
  // setScrolledToBottom,
} = messengerReducer.actions
export default messengerReducer.reducer
